var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { style: { height: "300px" }, attrs: { id: "trend" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }